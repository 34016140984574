<template>
  <div class="chart__wrapper">
    <v-row>
       <v-col cols="12" sm="3" md="4" lg="2" xs="12">
        <div class="donut-chart">
      <wj-flex-pie
        ref="chart"
        :dataLabel="false"
        :innerRadius="0.8"
        :itemsSource="data"
        binding="emissions"
        :tooltipContent="tooltipContent"
        :palette="colors"
        :startAngle="90"
      >
        <wj-flex-chart-legend position="None"></wj-flex-chart-legend>
      </wj-flex-pie>
        </div>
      </v-col>
      <v-col cols="12" sm="9" md="8" lg="10" xs="12">
       <div class="data-table-warpper pl-9 ">
      <table class="data-table">
        <tr class="data-table__header">
          <th>{{ $t('product_dashboard_main.label_process') }}</th>
          <th>{{ $t('product_dashboard_main.label_emissions') }}</th>
          <th>{{ $t('product_dashboard_main.label_ratio') }}</th>
        </tr>
        <template v-for="(item, index) in data">
          <tr class="data-table__body" :key="index">
            <td :key="index" :class="index === 0 && 'pt-2'">
              <div class="table-body-wrapper">
                <div class="lable-color" :style="{ backgroundColor: item.color }"></div>
                <span class="color-monoBlack title-lable">{{ item.process_item_name }}</span>
              </div>
            </td>
            <td class="color-goldMid" :class="index === 0 && 'pt-2'">
              <div>
                <span class="color-goldMid value-data">{{ formatNumber(item.emissions, { formatUnit: getUnitSetting.CO2 }) || '---' }}</span>
                <span class="color-goldMid lable-data">{{getUnitChartDonutDetail}}</span>
              </div>
            </td>
            <td class="color-goldMid" :class="index === 0 && 'pt-2'">
              <div>
                <span class="color-goldMid value-data">{{ formatNumber(item.percentage, { formatUnit: getUnitSetting.PERCENT }) || '---' }}</span>
                <span class="color-goldMid lable-data">%</span>
              </div>
            </td>
          </tr>
        </template>
      </table>
       </div>
    </v-col>
    </v-row>
  </div>
</template>
<script>
import '@mescius/wijmo.styles/wijmo.css';
import '@mescius/wijmo.vue2.chart';
import { PALETTE_COLOR } from '@/constants/products/define-data';
import { formatValue, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { mapState } from 'vuex';
import { formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';
import { getUnitNewDashboardCFP } from '@/utils/new-cfp-dashboard';

export default {
  props: {
    dataItem: [],
    totalEmission: {
      type: String,
      default: ''
    },
    totalForAllProduct: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      data: [],
      sum: null,
      palette: PALETTE_COLOR,
    };
  },
  async mounted() {
    this.data = this.dataItem;
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].color = this.colors[i];
      this.data[i].percentage = this.getPercentage(this.data[i].emissions);
    }
  },

  methods: {
    getPercentage(emission) {
      const totalEmissionVal = math.bignumber(formatValue(this.totalForAllProduct))
      const emissionsVal = math.bignumber(formatValue(emission))
      if(emissionsVal.toString() === '0' || totalEmissionVal.toString() === '0') {
        return '0'
      }
      const percentage = formatBigNumber(math.evaluate(`${formatValue(emissionsVal.toString())} / ${formatValue(totalEmissionVal.toString())} * 100`))
      return percentage
    },
    formatNumber(num, options) {
      if(['0', '0.00', 0].includes(num)) {
        return '---'
      }
      return formatNumberByConditions(formatBigNumber(num, 50).replace(/[^0-9\.\-]+/g, ''), options);
    },
    tooltipContent(hti) {
      let item = hti.item;
      return `<div style='display: flex;gap: 4px;flex-direction: column;padding: 4px; min-width: 131px; width: 100%;font-family: 'Source Han Sans';'>
                <div style="color: #A9A04B;letter-spacing: 0.03em;line-height: 18px;font-size: 11px;font-weight: 700;">${item?.process_item_name}</div>
                <div style="display: flex;justify-center: space-between; align-items: center; gap: 8px;width: 100%;min-width: 131px;">
                  <div style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;min-width: 45px;'>${this.$t("product_dashboard_main.tooltip_emission")}</div>
                  <div style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;'>${this.formatNumber(item?.emissions, { formatUnit: UNIT_SETTING.CO2 })} ${this.getUnitChartDonutDetail}</div>
                </div>
                <div style="display: flex;justify-center: space-between; align-items: center; gap: 8px;min-width: 100%;">
                  <span style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;min-width: 45px;'>${this.$t("product_dashboard_main.label_ratio")}</span>
                  <span style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;'>${this.formatNumber(item?.percentage , { formatUnit: UNIT_SETTING.PERCENT })} %</span>
                </div>
              </div>`
    },
  },

  computed: {
    ...mapState('product', ['dashboard', 'colors']),
    getUnitSetting() {
      return UNIT_SETTING;
    },
    getUnitChartDonutDetail() {
      return getUnitNewDashboardCFP();
    },
  }
};
</script>
<style lang="scss" scoped>
.wj-flexchart {
  height: 144px;
  width: 144px;
  border: unset;
}
.color-goldMid {
  color: $goldMid;
}
.color-monoBlack {
  color: $monoBlack;
}

th:first-child,
td:first-child {
  text-align: start;
}
th:first-child {
  padding-left: 34px;
}
th:nth-child(2),
td:nth-child(2) {
  text-align: right;
}
th:nth-child(3),
td:nth-child(3) {
  padding-left: 60px;
  text-align: right;
}
.lable-data {
  padding-left: 4px;
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}
.value-data {
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.05em;
}
.lable-color {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 16px;
  min-width: 10px;
}
.title-lable {
  font-size: 12px;
}
.donut-chart {
  padding: 7px 0;
}

.chart__wrapper {
  display: flex;
  width: 100%;
  padding: 16px 24px;

  .data-table-warpper {
    overflow-y: auto;
    width: 100%;
    padding-left: 22px;

    .data-table {
      width: 100%;
      border-collapse: collapse;
      .data-table__header {
        border-bottom: 1px solid rgba(42, 42, 48, 0.1);
        th {
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          padding-bottom: 8px;
          color: $monoBlack;
          letter-spacing: 0.03em;
        }
        td {
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
        }
      }
      .data-table__body {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        padding: 0px 8px;
        gap: 16px;

        width: 100%;
        min-height: 22px;
        tr {
          padding: 8px 0;
        }
        .table-body-wrapper {
          display: flex;
          align-items: center;
          padding-left: 8px;
        }
      }
    }
  }
}
</style>
