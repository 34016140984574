<template>
  <div class="height-emission-chart">
    <page-title
      :style="styleMarginTop"
      :label="$t('product_dashboard_main.title_product_high_emission')"
      iconPath="page-title-heigh-emission.svg"
    />
    <tab-header class="mt-12 tabs-header" :tab="selectedTab" @update:tab="handleUpdateTab" />
    <div class="tab-year-select" v-if="selectedTab === 0">
      <v-select
        solo
        flat
        :items="getYearList"
        v-model="currentYear"
        @change="handleGetDataChartUpdatedByYear(currentYear)"
        :label="$t('dashboard_main.placeholder_select_item_label')"
        item-text="value"
        item-value="value"
        class="select-type select-default select-types list-menu--select select-item durations-select-item mt-0 mb-12"
        :menu-props="{ contentClass: 'select-menu product-select' }"
      />
    </div>
    <div v-else class="filter-durations">
      <filter-duration
        :startMonthProps="startMonthProps"
        :durationsSelectedData="durationsSelectedData"
        @filterUpdated="handleGetDataChartUpdated"
        :tab="selectedTab"
      />
    </div>
    <div class="highEmission-wrapper">
      <detail-chart
        class="mt-4"
        :selectedTab="selectedTab"
        :branchIds="branchIds"
        :filterUpdatedKeys="filterUpdatedKeys"
        :isHasBranchNull="isHasBranchNull"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import PageTitle from '@/components/products/common/page-title.vue';
import DetailChart from '@/components/products/charts/high-emission/index.vue';
import TabHeader from '@/components/products/common/tab-header.vue';
import { getMonthListCustome } from '@/utils/registerData';
import FilterDuration from '@/components/products/charts/filter-duration/index.vue';
import { isDashboardProductCFP } from '@/api/product/emissionsProduct';

export default {
  components: {
    PageTitle,
    TabHeader,
    DetailChart,
    FilterDuration,
  },
  props: {
    durationList: {
      type: Array,
      default: () => [],
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    isHasBranchNull: {
      type: Boolean,
      default: false,
    },
    yearList: {
      type: Array,
      default: () => [],
    },
    startMonthProps: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: [],
      tab: 0,
      selectedTab: 0,
      showChart: false,
      selectedDuration: 0,
      defaultDurationId: 0,
      defaultIsSelected: false,
      dataQuantity: {
        quantity: null,
        quantitys: [],
      },
      product: [],
      dataType: {
        type: 1,
        types: [],
      },
      types: [
        { value: 1, text: '全製品の排出量' },
        { value: 2, text: '1製品あたりの排出量' },
      ],
      quantitys: [],
      selectDurationChange: null,
      dataList: [],
      durationsSelectedData: {},
      filterUpdatedKeys: 0,
      currentYear: null,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      getSelectedTypeForHighEmissions: 'dashboard/getSelectedTypeForEmissionsByCategory',
      getDurationHighEmissionsWithYearly: 'dashboard/getDurationByCategoryWithYearly',
    }),
    ...mapState('product', ['dashboard']),
    getYearProps() {
      return this.selectedTab === 0 ? this.yearTypeYear : this.yearTypeMonth;
    },
    getYearList() {
      let yearList = [...this.yearList];
      if (this.startMonthProps > 1) {
        yearList.shift();
      }
      return yearList;
    },
    isNewDashboarRoute() {
      return isDashboardProductCFP(this.$route);
    },
    styleMarginTop() {
      return this.isNewDashboarRoute ? '' : 'margin-top: 48px';
    },
  },
  created() {
    this.selectedTab = this.dashboard.emissionsHighProducts?.selectedType;
    this.updateSelectedDurationStore();
    this.selectedFirstItem();
  },

  watch: {
    // selectedTab(newVal) {
    //   this.checkDataBeforeRender()
    // },
    'dataQuantity.quantity': {
      handler() {
        const arr = [];

        this.data = this.getData();
        const data = this.data.slice(0, this.dataQuantity.quantity);
        this.data = data;
        this.data.map((item) => {
          arr.push({ ...item, showChart: false });
        });
        this.data = arr;
      },
    },
    deep: true,
    getYearProps(newVal) {
      this.monthList = getMonthListCustome(newVal, this.startMonthProps);
      const currentIndexMonth = this.monthList.findIndex((item) => item.value === this.month);
      if (currentIndexMonth < 0 && this.selectedTab > 0) {
        this.month = this.dashboard.emissionsHighProducts.selectedData?.month || this.monthList[0].value;
      }
      this.keyMonth++;
    },
    selectedTab: {
      handler() {
        this.updateSelectedDurationStore();
      },
    },
  },
  methods: {
    ...mapActions('product', ['updateEmissionsHighProducts']),
    selectedFirstItem() {
      let index = -1;
      if (this.selectedTab === 0) {
        const oldYearSelected = this.durationsSelectedData?.selectedYear;
        index = this.getYearList.findIndex((item) => item?.value === oldYearSelected?.year);
        if (index < 0) {
          index = 0; // current FY
        }
        this.currentYear = this.getYearList[index]?.value;
      }
      this.updateEmissionsHighProducts({
        ...this.dashboard.emissionsHighProducts,
        selectedData: {
          ...this.dashboard.emissionsHighProducts.selectedData,
          selectedYear: { year: this.currentYear },
        },
      });
    },
    handleGetDataChartUpdatedByYear(year) {
      this.updateEmissionsHighProducts({
        ...this.dashboard.emissionsHighProducts,
        selectedData: {
          ...this.dashboard.emissionsHighProducts.selectedData,
          selectedYear: { year: year },
        },
      });
      this.filterUpdatedKeys++;
    },
    handleGetDataChartUpdated(store) {
      this.updateEmissionsHighProducts({
        ...this.dashboard.emissionsHighProducts,
        selectedData: {
          ...this.dashboard.emissionsHighProducts.selectedData,
          ...store,
        },
      });
      this.filterUpdatedKeys++;
    },
    updateSelectedDurationStore() {
      this.durationsSelectedData = {
        selectedYear: this.dashboard.emissionsHighProducts?.selectedData?.selectedYear,
        selectedHalfYear: this.dashboard.emissionsHighProducts?.selectedData?.selectedHalfYear,
        selectedQuarterYear: this.dashboard.emissionsHighProducts?.selectedData?.selectedQuarterYear,
        selectedMonthYear: this.dashboard.emissionsHighProducts?.selectedData?.selectedMonthYear,
      };
    },
    getData() {
      return [
        {
          name: 'A',
          quantity: 123,
          value: 122,
          all: 222,
          data: [
            { name: '行程項目名', emissions: 321, percentage: 10 },
            { name: '行程項目名', emissions: 215, percentage: 10 },
            { name: '行程項目名', emissions: 160, percentage: 10 },
            { name: '行程項目名', emissions: 112, percentage: 10 },
            { name: '行程項目名', emissions: 100, percentage: 10 },
            { name: '行程項目名', emissions: 638, percentage: 10 },
          ],
        },
        {
          name: 'B',
          quantity: 123,
          value: 122,
          all: 222,
          data: [
            { name: '行程項目名', emissions: 321, percentage: 10 },
            { name: '行程項目名', emissions: 215, percentage: 10 },
            { name: '行程項目名', emissions: 160, percentage: 10 },
            { name: '行程項目名', emissions: 112, percentage: 10 },
            { name: '行程項目名', emissions: 100, percentage: 10 },
            { name: '行程項目名', emissions: 638, percentage: 10 },
          ],
        },
        {
          name: 'C',
          quantity: 123,
          value: 122,
          all: 222,
          data: [
            { name: '行程項目名', emissions: 321, percentage: 10 },
            { name: '行程項目名', emissions: 215, percentage: '' },
            { name: '行程項目名', emissions: 160, percentage: '' },
            { name: '行程項目名', emissions: 112, percentage: 10 },
            { name: '行程項目名', emissions: 100, percentage: 10 },
            { name: '行程項目名', emissions: '', percentage: 10 },
          ],
        },
      ];
    },
    handleUpdateTab(newTab) {
      this.selectedTab = newTab;
      this.updateEmissionsHighProducts({
        ...this.dashboard.emissionsHighProducts,
        selectedType: newTab,
      });
      this.selectedFirstItem();
    },
  },
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.color-goldMid {
  color: $goldMid;
}

.color-monoBlack {
  color: $monoBlack;
}

.height-emission-chart {
  min-height: 250px;
}
.tabs-header {
  margin-bottom: 16px;
}
.tab-year-select {
  width: 100%;
  @include desktop {
    width: 100px;
  }
}
@include desktop {
  .highEmission-wrapper {
    margin-top: -38px;
  }
}
</style>
