<template>
  <div class="highEmission-wrapper">
    <div class="chart-highEmission">
      <div class="chart-wrapper">
        <div class="header-chart" :class="getClassHeaderChart">
          <v-select
            solo
            flat
            :items="selectDataList.type"
            v-model="selectedData.type"
            label="年月を選択"
            no-data-text="''"
            class="select-type select-types list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu product-select high-emissions' }"
            @change="handleCloseChartDetail"
          />
          <div class="chart-quatity">
            <span class="lable-quantity">{{ $t('product_dashboard_main.label_displayed_results') }}：</span>
            <v-select
              solo
              flat
              :items="selectDataList.quantity"
              v-model="selectedData.quantity"
              label="年月を選択"
              no-data-text="''"
              class="select-type select-quatity list-menu--select select-item"
              :menu-props="{ contentClass: 'select-menu product-select high-emissions' }"
            />
            <span class="lable-quantity">{{ $t('b_register_product.label_item') }}</span>
          </div>
        </div>
        <div class="chart-detail" v-for="(item, index) in dataList" :key="index">
          <div :key="index" class="item-wrapper">
            <div class="chart-item item-name">
              <div class="item-title">
                <span class="item-id">{{ index + 1 }}</span>
                <span class="item-name color-goldMid" :id="'parent-product' + index" @click="redirectToDetailProduct(item)">
                  <span :ref="'product' + index"> 
                    <div class="product-title" style="display: inline-block; text-decoration-line: underline;">
                      {{ $t('product_dashboard_main.hyperlink_product') }}
                    </div>
                    {{ getProductName(item) }}
                  </span>
                </span>
              </div>
            </div>
            <div class="chart-item number-item d-flex">
              <div class="quantity">
                <div class="production-quantity item-porduction">
                  <span class="product-name">{{ $t('product_dashboard_main.label_production_quantity') }}</span>
                  <div>
                    <span class="product-value color-goldMid">{{ formatNumber(item.quantity, { formatUnit: getUnitSetting.CO2 }) || '---' }}</span>
                    <span class="value-lable color-goldMid" v-if="item.quantity">{{ item.quantity_name }}</span>
                  </div>
                </div>
              </div>
              <div class="amount" v-if="isShowAmount">
                <div class="production-value item-porduction">
                  <span class="product-name">{{ $t('product_dashboard_main.label_production_value') }}</span>
                  <div>
                    <span class="product-value color-goldMid">{{ formatNumber(item.amount, { formatUnit: getUnitSetting.CURRENCY }) || '---' }}</span>
                    <span class="value-lable color-goldMid" v-if="item.amount">{{ item.amount_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="chart-item description">
              <div class="production-emissions item-porduction" :class="chartDetailClass">
                <span class="product-name">{{descriptionText}}</span>
                <div class="emissions_wrapper">
                  <span class="product-value color-goldMid">{{ formatNumber(item.total_emission, { formatUnit: getUnitSetting.CO2 }) || '---' }}</span>
                  <span class="value-lable color-goldMid" v-if="item.total_emission">{{getUnitChartDetail}}</span>
                  <div class="btn-show-detail " :class="removeButtonShowDetail" @click="handleShowPopupChartDetail(index)">{{ $t('product_dashboard_main.button_breakdown') }}</div>
                  <div class="button-item" v-if="isShowAmount" @click="handleShowChart(index)">
                    <div class="button-wrapper">
                      <img :src="handleIcon(index)" alt="icon" class="icon-normal" />
                      <img :src="handleIconHover(index)" alt="icon" class="icon-hover" />
                      <span class="lable-button color-monoBlack">{{ $t('product_dashboard_main.button_breakdown') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-detail" v-if="!isShowAmount || item.showChart" :key="index + selectDataList.type">
            <div class="detail-chart">
              <div class="header-table">
                <span class="lable-header color-monoBlack">{{ $t('product_dashboard_main.label_breakdown_emissions') }}</span>
              </div>
              <div class="table-chart">
                <div class="ui-chart">
                  <ChartDonut :dataItem="item.process_items" :totalEmission="item.total_emission" :totalForAllProduct="getTotalForAllProduct(item.id)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DetailHeightEmissionChart :dialog="dialogChartHeightEmissionPopup" :detailDataChart="detailDataSelect" @close="dialogChartHeightEmissionPopup = false"  />
</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import ChartDonut from '@/components/products/charts/high-emission/donut-chart/index.vue';
import SelectItem from '@/components/utils/selectItem.vue';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import DetailHeightEmissionChart from '@/components/products/dialogs/DetailHeightEmissionChart.vue';
import { getDataListMostEmissionDashboardGeneral, isDashboardProductCFP } from '@/api/product/emissionsProduct';

import { formatValue, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { PALETTE_COLOR } from '@/constants/products/define-data';
import { isEnglishLanguage } from '@/utils/multiLanguage.js';
import { formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';
import { getUnitNewDashboardCFP } from '@/utils/new-cfp-dashboard';


export default {
  components: {
    ChartDonut,
    SelectItem,
    DetailHeightEmissionChart,
    DefaultPullDownMenu
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    isHasBranchNull: {
      type: Boolean,
      default: false,
    },
    filterUpdatedKeys: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectedDuration: null,
      selectedData: {
        type: 1,
        quantity: 5,
      },
      selectDataList: {
        type: [
          { value: 1, text: this.$t('product_dashboard_main.pulldown_select_emission_all_product') },
          { value: 2, text: this.$t('product_dashboard_main.pulldown_select_emissions_per_product') },
        ],
        quantity: [],
      },
      totalProductList: [],
      perProductList: [],
      detailDataSelect: [],
      palette: PALETTE_COLOR,
      dialogChartHeightEmissionPopup: false,
      selectedYear: null
    };
  },
  mounted() {
    // this.selectedYear = 2023

    this.setDefaultData();
    [5, 10, 25].forEach(item => {
      this.selectDataList.quantity.push({
        value: item,
        text: item,
      })
    })
    const dataStore = this.dashboard.emissionsHighProducts

    this.selectedData = {
      type: dataStore.highEmissionsForAllProducts ? 1 : 0,
      quantity: dataStore.selectedData.numberItems || 5,
    }
    this.getDataList();
    this.setWidthByRef()
  },
  watch: {
    selectedData: {
      handler() {
        this.updateDataToStore();
      },
      deep: true,
    },
    isChangeProps() {
      this.getDataList();
    },
    selectedYear(newVal) {
      this.$emit('updateSelectYear', newVal)
    },
    dataList: {
      handler() {
        this.setWidthByRef()
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('product', ['dashboard', 'colors']),
    isShowAmount() {
      return !isDashboardProductCFP(this.$route);
    },
    getClassHeaderChart() {
      return isEnglishLanguage() ? 'en-language' : ''
    },
    dataList() {
      let data = this.selectedData.type === 1 ? this.totalProductList : this.perProductList;
      let itemSlice = this.selectedData.quantity
      data = data.slice(0, itemSlice)
      return data;
    },
    descriptionText() {
      return this.selectedData.type === 1 ? this.$t('product_dashboard_main.label_emission_all_product') : this.$t('product_dashboard_main.label_emission_per_product');
    },
    isChangeProps() {
      // to watch month && duration
      return this.filterUpdatedKeys + this.selectedTab + this.branchIds + this.selectedDuration + this.isHasBranchNull;
    },
    getUnitSetting() {
      return UNIT_SETTING;
    },
    chartDetailClass() {
      return !this.isShowAmount ? 'chart-detail-new-dashboard' : '';
    },
    getUnitChartDetail() {
      return getUnitNewDashboardCFP();
    },
    removeButtonShowDetail() {
      return !this.isShowAmount ? 'remove-btn-show-detail' : '';
    },
  },
  methods: {
    ...mapActions('product', ['updateEmissionsHighProducts', 'actionUpdateColorProducts']),
    formatNumber(num, options) {
      if(num === 0) {
        return '---'
      }
      return formatNumberByConditions(formatBigNumber(num, 50).replace(/[^0-9\.\-]+/g, ''), options);
    },
    getRandomColor() {
      const r = Math.floor(Math.random() * 256); 
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const alpha = 1;

      return `rgba(${r},${g},${b},${alpha})`;
    },
    getParamsDurationOfStore() {
      let paramsStore = {};
      switch (this.selectedTab) {
          case 0:
            paramsStore.year = this.dashboard.emissionsHighProducts?.selectedData?.selectedYear?.year;
            break;
          case 1:
            const oldMonthYearSelected =
              this.dashboard.emissionsHighProducts?.selectedData?.selectedMonthYear;
              const [oldYear, oldMonth] = oldMonthYearSelected?.selected_range?.split('/');
              paramsStore.month = parseInt(oldMonth);
              paramsStore.year = oldYear;
              break;
          default:
            paramsStore.year = this.dashboard.emissionsHighProducts?.selectedData?.selectedYear?.year;
          break;
      }
      return paramsStore;
    },
    getDataList() {
      this.totalProductList = [];
      this.perProductList = [];
      
      const payload = {
        branch_null: this.isHasBranchNull ? 1 : null,
        type: this.selectedTab,
        branch_id: this.branchIds,
        ...this.getParamsDurationOfStore()
      }

      //apply data to store
      this.updateDataToStore();
      getDataListMostEmissionDashboardGeneral(payload,this.$route).then(res => {
        res.data.total_product.forEach(item => {
          this.totalProductList.push({
            ...item,
            showChart: false,
          })
        })
        res.data.per_product.forEach(item => {
          this.perProductList.push({
            ...item,
            showChart: false,
          })
        })

        let colors = []
        for (let index = 0; index < 200; index++) {
          colors.push(this.getRandomColor())
        }
        this.actionUpdateColorProducts(colors)
      })
    },
    setDefaultData() {
      this.selectedData.type = this.dashboard.emissionsHighProducts.highEmissionsForAllProducts;
      let updateSelectedData = {
        selectedTab: this.dashboard.emissionsHighProducts.selectedType,
        month: this.dashboard.emissionsHighProducts.selectedType === 1 ? this.dashboard.emissionsHighProducts.selectedData.month : null,
        year: this.dashboard.emissionsHighProducts.selectedType === 1 ? this.dashboard.emissionsHighProducts.selectedData.yearTypeYear : this.dashboard.emissionsHighProducts.selectedData.yearTypeMonth,
      }
      this.selectedDuration = this.dashboard.emissionsHighProducts.selectedType === 0 ? this.dashboard.emissionsHighProducts.selectedData.duration_id : null;
      this.$emit('updateSelectedData', updateSelectedData);
    },
    updateDataToStore() {
      const selectedData = this.dashboard.emissionsHighProducts.selectedData
      this.updateEmissionsHighProducts({
        selectedType: this.selectedTab,
        highEmissionsForAllProducts: this.selectedData.type,
        selectedData: {
          ...this.dashboard.emissionsHighProducts.selectedData,
          duration_id:  this.selectedTab === 0 ? this.selectedDuration : selectedData.duration_id,
          numberItems: this.selectedData.quantity,
        }
      })
    },
    handleIcon(index) {
      if (this.selectedData.type === 1) {
        if (this.totalProductList[index].showChart === true) {
          return require('@/assets/icons/product/dashboard/close-load-more.svg');
        } else return require('@/assets/icons/product/dashboard/load-more.svg');
      } else {
        if (this.perProductList[index].showChart === true) {
          return require('@/assets/icons/product/dashboard/close-load-more.svg');
        } else return require('@/assets/icons/product/dashboard/load-more.svg');
      }
    },
    handleIconHover(index) {
      if (this.selectedData.type === 1) {
        if (this.totalProductList[index].showChart === true) {
          return require('@/assets/icons/product/dashboard/close-load-more-active.svg');
        } else return require('@/assets/icons/product/dashboard/load-more-active.svg');
      } else {
        if (this.perProductList[index].showChart === true) {
          return require('@/assets/icons/product/dashboard/close-load-more-active.svg');
        } else return require('@/assets/icons/product/dashboard/load-more-active.svg');
      }
    },
    handleShowChart(index) {
      if (this.selectedData.type === 1) {
        this.totalProductList[index].showChart = !this.totalProductList[index].showChart;
      } else {
        this.perProductList[index].showChart = !this.perProductList[index].showChart;
      }
    },
    getPercentage(emission, totalEmission) {
      const totalEmissionVal = math.bignumber(formatValue(totalEmission))
      const emissionsVal = math.bignumber(formatValue(emission))
      const percentage = formatBigNumber(math.evaluate(`${emissionsVal.toString()} / ${totalEmissionVal.toString()} * 100`))
      return percentage
    },
    handleShowPopupChartDetail(index) {
      // this.detailDataSelect = {}
      let itemSelected = {}
      if (this.selectedData.type === 1) {
        itemSelected = this.totalProductList[index]
      } else {
        itemSelected = this.perProductList[index]
      }
      
      this.detailDataSelect = itemSelected.process_items.map((item, index) => {
        const dataItem = {
          emissions: formatValue(item.emissions),
          process_item_name: item.process_item_name,
          color: this.colors[index],
        }
        return dataItem
      })
      this.dialogChartHeightEmissionPopup = true
    },
    redirectToDetailProduct(item) {
      if(!item.id) return
      if (isDashboardProductCFP(this.$route)) {
        const newQuery = {
          ...this.$route.query,
          type: 'detail',
        };
        this.$router.push({ name: 'DetailProduct', params: { id: item.id }, query: newQuery }).catch(() => {});
      }
    },
    handleCloseChartDetail(newType) {
      if(newType === 1) {
        this.totalProductList = this.totalProductList.map(item => {
          return {
            ...item,
            showChart: false
          }
        })
      } else {
        this.perProductList = this.perProductList.map(item => {
          return {
            ...item,
            showChart: false
          }
        })
      }
    },
    getTotalForAllProduct(productId) {
      return this.totalProductList.find(product => product.id === productId)?.total_emission
    },
    setWidthByRef() {
      this.$nextTick(() => {
        for(let refName in this.$refs) {
          const widthOffSpan = this.$refs[refName][0]?.offsetWidth
          const element = document.getElementById(`parent-${refName}`);
          const childElement = element.querySelectorAll('span')[1]
          if(element && childElement) {
            element.addEventListener('mouseenter', function() {
              childElement.style.width = `${widthOffSpan}px`;
            });

            element.addEventListener('mouseleave', function() {
              childElement.style.width = 0; // Revert set width
            });
          }
        }
      })
    },
    getProductName(item) {
      return this.isShowAmount ? item.name : `${item.name}/${item.lot_number || ''}`;
    },
  },
  beforeDestroy() {
    this.updateDataToStore();
  }
}
</script>
<style lang="scss">
.highEmission-wrapper {
  .header-chart {
    .v-select__selection,
    .v-list-item__title {
      font-weight: 700;
      font-size: 11px;
      line-height: 18px;
    }
    .v-input.list-menu--select .v-input__control {
      min-height: 28px;
      .v-input__slot {
        box-shadow: unset !important;
        border: 1px solid rgba(42, 42, 48, 0.1);
        border-radius: 4px;
        padding-left: 8px !important;
        .v-select__slot {
          .v-input__append-inner {
            padding: 9px !important;
          }
        }
      }
    }
    .v-select--is-menu-active {
      .v-input__slot {
        border: 2px solid $blueMid!important;
      }
    }
  }
}

.chart-highEmission {
  .menuable__content__active {
    top: 2073px!important;
  }
  .v-select__selection--comma {
    overflow: hidden !important;
  }
}

.input-quatity {
  &.v-input {
    height: 28px;
    .v-input__control {
      border-radius: 4px;
      background: $monoOffWhite; 
      min-height: 28px;
      height: 28px;
      .v-input__slot {
        border: 1px solid rgba(42, 42, 48, 0.1);
        height: 28px !important;
        max-height: 28px !important;
        background: $monoOffWhite !important;

        .v-text-field__slot {
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.03em;
          color: $monoBlack;
          height: 28px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.select-element  {
  width: 100%;
}
.color-goldMid {
  color: $goldMid;
}
.color-monoBlack {
  color: $monoBlack;
}
.highEmission-header {
  margin-top: 40px;
  margin-bottom: 48px;
  .highEmission-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    margin-bottom: 16px;
    .highEmission__title {
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.1em;
      padding-left: 16.5px;
    }
  }
  .highEmission-icon {
    display: flex;
  }
}
.chart-highEmission {
  background-color: $monoWhite;
  height: max-content;
  .chart-wrapper {
    border-radius: 8px;
    .header-chart {
      padding: 8px;
      position: relative;
      height: 44px;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      flex-direction: row;
      width: 100%;
      .select-types {
        width: 167px;
        height: 28px;
      }
      .chart-quatity {
        display: flex;
        right: 24px;
        align-items: center;
        gap: 8px;
        .select-quatity {
          padding: 0 8px;
          width: 80px;
          height: 28px;
        }
      }
      .lable-quantity {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
      }
    }
  }
}
.item-wrapper {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 102px;
  .chart-item {
    max-height: 80px;
    max-height: 120px;
    padding: 8px 0;
  }
  .item-title {
    .item-id {
      padding: 2px 7px 3px 7px;
      background: rgba(121, 134, 134, 0.12);
      // margin-right: 16px;
      border-radius: 50%;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: $monoDark;
    }
    .item-name {
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      cursor: pointer;

      .product-title{
        display: inline-block;
        text-decoration: underline;
      }
    }
  }
  .emissions_wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
    .button-item {
      cursor: pointer;
      padding: 4px 8px 6px;
      background: $monoOffWhite;
      border: 1px solid $dividerBlack;
      border-radius: 4px;
      // min-width: 61px;
      // width: 61px;
      .button-wrapper {
        display: flex;
        gap: 0 6px;
        align-items: flex-end;
        justify-content: space-between;
        .icon-hover {
          display: none;
          width: 16px;
          height: 16px;
        }
        .icon-normal {
          display: block;
          width: 16px;
          height: 16px;
        }
        .true {
          .icon-active {
            display: none;
          }
        }
        .false {
          .icon-disable {
            display: none;
          }
        }
      }
      .lable-button {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
      }

      &:hover {
        .button-wrapper {
          .icon-hover {
            display: block;
          }
          .icon-normal {
            display: none;
          }
        }
      }
    }
  }
}
.detail-chart {
  padding: 0 24px 24px 60px;
  .header-table {
    padding: 8px 24px;
    background: rgba(121, 134, 134, 0.12);
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;
  }
  .lable-header {
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
  }
  .table-chart {
    background: #f7f7f2;
  }
}
.chart-detail {
  border-top: 1px solid $dividerBlack;
  display: block;
  .item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 0;
    .chart-item {
      width: max-content;
      &:first-child {
        padding: 0;
        max-width: 100%;
        .item-title {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .item-id {
            width: 20px;
          }
          .item-name {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &.number-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 20px;
        width: 100%;
        .quantity, .amount {
          width: 50%;
          .item-porduction {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 8px 0px;
            gap: 8px;
            min-height: 70px;
            .product-name {
              font-weight: 700;
              font-size: 11px;
              line-height: 18px;
              letter-spacing: 0.03em;
              color: $monoBlack;
            }
            .product-value {
              font-weight: 400;
              font-size: 20px;
              line-height: 22px;
              letter-spacing: 0.05em;
              word-break: break-all;
            }
            .value-lable {
              font-weight: 400;
              font-size: 11px;
              line-height: 18px;
              letter-spacing: 0.05em;
              margin-left: 8px;
            }
          }
        }
      }
      &.description {
        .item-porduction {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px 0px;
          gap: 8px;
          min-height: 70px;
          .product-name {
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
            color: $monoBlack;
          }
          .emissions_wrapper {
            padding: 0px;
            gap: 8px;
            // max-height: 28px;
            max-height: 65px;
            align-items: flex-start;
            .button-item {
              display: none;
            }
            .product-value {
              font-weight: 400;
              font-size: 20px;
              line-height: 22px;
              letter-spacing: 0.05em;
              word-break: break-all;
              max-width: calc(100vw - 139px); // padding is 48, gap 16, 75 is width of unit + button
            }
            .value-lable {
              font-weight: 400;
              font-size: 11px;
              line-height: 18px;
              letter-spacing: 0.05em;
              min-width: 35px;
            }
            .btn-show-detail {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              padding: 4px 8px 6px;
              gap: 6px;

              // width: 39px;
              height: 28px;
              background: $monoOffWhite;
              border: 1px solid rgba(42, 42, 48, 0.1);
              border-radius: 4px;

              font-weight: 700;
              font-size: 11px;
              line-height: 18px;
              letter-spacing: 0.03em;
              color: $monoBlack;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.v-input.list-menu--select .v-input__control {
  height: 28px !important;
  padding: unset !important;
}
.tabs-content {
  .theme--light.v-tabs-items {
    background-color: transparent;
  }
}

.chart-highEmission {
  background-color: $monoWhite;
  height: max-content;
  margin-inline: -20px;
  .chart-wrapper {
    .header-chart {
      padding: 8px 20px;
      position: relative;
      height: 44px;
      display: flex;
      gap: 8px;
      flex-direction: row;
      justify-content: space-between;
      .select-types {
        width: 167px;
        max-width: 167px;
        height: 28px;
      }
      .chart-quatity {
        display: flex;
        right: 24px;
        align-items: center;
        min-width: 160px;
        .select-quatity {
          padding: 0;
          width: 80px;
          height: 28px;
          width: 64px;
        }
        .input-quatity {
          display: block;
          width: 64px;
          height: 28px;
        }
      }
      .lable-quantity {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
      }
    }
  }
}
@include desktop {
  .select-element  {
    width: 300px;
  }
  .chart-highEmission {
    margin-inline: unset;
    border-radius: 8px;
    .chart-wrapper {
      .header-chart {
        .select-types {
          width: 328px;
          max-width: 328px;
        }
        .chart-quatity {
          .select-quatity {
            display: block;
            width: 80px;
          }
          .input-quatity {
            display: none;
          }
        }
      }
    }
  }
  .select-types {
    width: 328px;
    max-width: 328px;
  }
  .durations-select-item {
    max-width: 300px;
    &.chart-height {
      max-width: 100px;
    }
  }
  .product-name {
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    // padding-bottom: 16px;
  }
  .product-value {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
  }
  .value-lable {
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
  }

  .item-porduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tablist {
    margin-bottom: 20px;
  }
  .period-combobox {
    width: 300px;
    margin-bottom: 48px;
  }
  .production-emissions {
    padding-right: 92px;
    padding-right: 0;
  }
  .item-wrapper {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 102px;
    .chart-item {
      // max-height: 80px;
      max-height: 120px;
      max-height: unset;
      padding: 8px 0;
    }
    .item-title {
      .item-id {
        padding: 2px 7px 3px 7px;
        background: rgba(121, 134, 134, 0.12);
        // margin-right: 16px;
        border-radius: 50%;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: $monoDark;
      }
      .item-name {
        font-weight: 500;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
      }
    }
    .emissions_wrapper {
      display: flex;
      justify-content: center;
      align-items: baseline;
      .button-item {
        cursor: pointer;
        padding: 4px 8px 6px;
        background: $monoOffWhite;
        border: 1px solid $dividerBlack;
        border-radius: 4px;
        .button-wrapper {
          display: flex;
          gap: 0 6px;
          align-items: flex-end;
          justify-content: center;

          .true {
            .icon-active {
              display: none;
            }
          }
          .false {
            .icon-disable {
              display: none;
            }
          }
        }
        .lable-button {
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.03em;
          min-width: 23px;
        }
      }
    }
  }
  .chart-detail {
    border-top: 1px solid $dividerBlack;
    display: block;
    .item-wrapper {
      flex-direction: row;
      padding: 16px 24px;
      gap: 0;
      .chart-item {
        width: max-content;
        padding: 8px 0;
        &:first-child {
          padding: 8px 0;
          // width: max-content;
          max-width: 30%;
          width: 25%;
          min-height: 80px;
          display: flex;
          .item-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            .item-id {
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .item-name {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
            }
          }
        }
        &.number-item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 0;
          gap: 8px;
          min-width: 40%;
          width: 50%;
          justify-content: center;
          .quantity, .amount {
            width: 50%;
            .item-porduction {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0;
              gap: 8px;
              min-height: 70px;
              .product-name {
                font-weight: 700;
                font-size: 11px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: $monoBlack;
              }
              .product-value {
                font-weight: 400;
                font-size: 20px;
                line-height: 22px;
                letter-spacing: 0.05em;
                word-break: break-all;
              }
              .value-lable {
                font-weight: 400;
                font-size: 11px;
                line-height: 18px;
                letter-spacing: 0.05em;
                margin-left: 8px;
              }
            }
          }
        }
        &.description {
          // padding: 0;
          // width: 25%;
          width: 30%;
          .item-porduction {
            padding: 0;
            gap: 8px;
            min-height: 70px;
            padding-right: 0;
            align-items: stretch;
            .product-name {
              font-weight: 700;
              font-size: 11px;
              line-height: 18px;
              letter-spacing: 0.03em;
              color: $monoBlack;
            }
            .emissions_wrapper {
              padding: 0px;
              gap: 8px;
              max-height: 28px;
              max-height: unset;
              justify-content: flex-start;
              align-items: center;
              .button-item {
                display: block;
                &:hover {
                  background: #0072A3;
                  .button-wrapper {
                    .lable-button {
                      color: $monoWhite;
                    }
                  }
                }
              }
              .product-value {
                font-weight: 400;
                font-size: 20px;
                line-height: 22px;
                letter-spacing: 0.05em;
                word-break: break-all;
              }
              .value-lable {
                font-weight: 400;
                font-size: 11px;
                line-height: 18px;
                letter-spacing: 0.05em;
                margin-top: 6px;
              }
              .btn-show-detail {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@include large-desktop {
  .chart-detail {
    .item-wrapper {
      .chart-item {
        &.description {
          .item-porduction {
            padding-right: 91px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  .chart-detail {
    .item-wrapper {
      .chart-item {
        &:first-child {
          max-width: 25%;
          width: 25%;
          .item-title {
            max-width: 100%;
          }
        }
        &.number-item {
          min-width: 40%;
          width: 40%;
        }
        &.description {
          width: 35%;
        }
      }
    }
  }
}
.chart-detail-new-dashboard {
  padding-right: 24px !important;
}
.remove-btn-show-detail{
    display: none !important;
}

</style>