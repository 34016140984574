<template>
  <div class="tabs-header tab-product-period tabs-header-category">
    <v-tabs v-model="selectedTab" color="#404D50" background-color="transparent">
      <v-tab v-for="(item, index) in defaultType" :key="index">
        {{ item }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'TabHeader',
  props: {
    tab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      defaultType: [this.$t('dashboard_detail.label_by_registration_year'), this.$t('dashboard_main.tab_by_month')],
    };
  },
  computed: {
    selectedTab: {
      get() {
        return this.tab;
      },
      set(value) {
        this.$emit('update:tab', value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs-header {
  width: 100%;
}
</style>
