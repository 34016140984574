<template>
  <div class="line-chart-wrapper">
    <div class="line-chart-actions-top" style="height: 24px;">
  </div>
  <div class="line-chart-actions-top">
    <button class="chart-btn-action btn-prev" 
      @click="prev" 
      @mouseover="isMouseOverPrev = true"
      @mouseleave="isMouseOverPrev = false" 
      @mousedown="isBlurPrev = true" 
      @mouseup="isBlurPrev = false"
      :disabled="isDisabledPrev"
    >
      <img :src="prevIcon" alt="Prev" />
    </button>
    <button 
      class="chart-btn-action btn-next" 
      @click="next" 
      @mouseover="isMouseOverNext = true"
      @mouseleave="isMouseOverNext = false" 
      @mousedown="isBlurNext = true" 
      @mouseup="isBlurNext = false"
      :disabled="isDisabledNext"
    >
      <img :src="nextIcon" alt="Next" />
    </button>
  </div>
    <div class="chart line-chart line-chart-month">
      <wj-flex-chart 
        ref="chart" 
        :key="key"
        chartType="LineSymbols" 
        :dataLabel="false" 
        :itemsSource="data"
        :initialized="onChartInitialized" 
        :tooltipContent="customTooltip" 
        :rendered="onChartRendered" 
        :palette="getColor"
      >
        <wj-flex-chart-animation :initialized="initializeAnimation" :easing="easing" :duration="500"></wj-flex-chart-animation>
      </wj-flex-chart>
      <div class="selected-div" :style="selectedDivStyle"></div>
      <div class="hover-div" :style="hoverDivStyle"></div>
    </div>
    <div class="line-chart-actions-bottom">
      <button class="chart-btn-action btn-prev" 
        @click="prev" 
        @mouseover="isMouseOverPrev = true"
        @mouseleave="isMouseOverPrev = false" 
        @mousedown="isBlurPrev = true" 
        @mouseup="isBlurPrev = false"
        :disabled="isDisabledPrev"
      >
        <img :src="prevIcon" alt="Prev" />
      </button>
      <button 
        class="chart-btn-action btn-next" 
        @click="next" 
        @mouseover="isMouseOverNext = true"
        @mouseleave="isMouseOverNext = false" 
        @mousedown="isBlurNext = true" 
        @mouseup="isBlurNext = false"
        :disabled="isDisabledNext"
      >
        <img :src="nextIcon" alt="Next" />
      </button>
    </div>
    <div style="height: 10px"></div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import '@mescius/wijmo.vue2.chart';
import '@mescius/wijmo.vue2.chart.animation';
import '@mescius/wijmo.styles/wijmo.css';
import { Series } from '@mescius/wijmo.chart';
import { TABLET_WIDTH } from '@/constants/screen-size';
import { PALETTE_COLOR } from '@/constants/products/define-data';
import { getPeriodProductDashboardGeneral, isDashboardProductCFP } from '@/api/product/emissionsProduct';
import { formatValue, $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
import { getFieldNameDashboardProduct, getUnitNewDashboardCFP } from '@/utils/new-cfp-dashboard';

import moment from 'moment';
import { prepareChartAxisYLineChart, formatYAxisSmallerThan1 } from '@/concerns/chart';
import { numberFormat } from '@/filters/number';
import { formatNumberRealNum, formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';

const TYPE_CHART = 'month';
const MAX_CHART_ITEMS = 4;
const LABEL_PADDING_X = 16;
const LABEL_PADDING_Y = 26;
export default {
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    productsMasterList: {
      type: Array,
      default: () => [],
    },
    productMasterDefault: {
      type: Array,
      default: () => [],
    },
    durationList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
    },
    isHasBranchNull: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      easing: 'EaseInQuad',
      animationMode: 'Point',
      min: 0,
      max: 500,
      axisXItemsSource: [],
      chartValue: [],
      selectedTimeRange: '',
      hoverTimeRange: '',
      isDisabledPrev: true,
      isDisabledNext: false,
      isMouseOverNext: false,
      isMouseOverPrev: false,
      isBlurNext: false,
      isBlurPrev: false,
      isFirstRender: true,
      transitionWidth: '100%',
      footerWidth: 0,
      minWidth: 0,
      data: [],
      breakDownData: [],
      selectionStart: null,
      selectionEnd: null,
      chart: null,
      selectedDivStyle: {},
      hoverDivStyle: {},
      previousRectElement: null,
      palette: PALETTE_COLOR,
      rawData: [],
      key: 0,
      pageCurrent: 0,
      everyIsNull: false,
    };
  },

  created () {
  },

  async mounted() {
    await this.innitDataVisualize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapState('product', ['dashboard', 'colors']),
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return '/img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return '/img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return '/img/icons/next-hover.svg';
      }

      return '/img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return '/img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return '/img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return '/img/icons/prev-hover.svg';
      }

      return '/img/icons/prev.svg';
    },
    hasNegativeNumber() {
      const existNegativeNumber = this.chartValue.find(
        (item) => item.emission_scope1 < 0 || item.emission_scope2 < 0 || item.emission_scope3 < 0,
      );
      return existNegativeNumber ? true : false;
    },
    getColor() {
      return this.colors;
    },
    isNewDashboarRoute() {
      return isDashboardProductCFP(this.$route);
    },
    getUnitChartLineMonth() {
      return getUnitNewDashboardCFP();
    },
  },
  destroyed() {
    this.handleOffEvent()
  },
  methods: {
    ...mapActions('product', ['updateEmissionsByProducts']),
    
    updateSelected() {  
      if (window.innerWidth < TABLET_WIDTH) {
        this.selectedDivStyle.display = 'none';
        return;
      }
      const oldMonthYearSelected = this.dashboard.emissionsByProducts.selectedData;
      let index = this.breakDownData.findIndex(item => item?.month === oldMonthYearSelected?.month);
      if(index < 0) {
        index = this.breakDownData.length - 1;
      }
      const selectedItem= this.breakDownData[index];
      let indexColumn = this.data.findIndex(item => item && item.month === selectedItem?.month);
      if(this.pageCurrent !== 0) {
        indexColumn = indexColumn - 1
      }
      if(indexColumn >= 0) {
        this.$emit('selectChart', this.breakDownData[index], window.innerWidth < TABLET_WIDTH);
        this.selectedDivStyle = this.updateStylesDiv(indexColumn)
        this.selectedDivStyle.backgroundColor = '#e3eded'
        this.addSelectedClass(indexColumn);
      }
      else {
        this.selectedDivStyle.display = 'none'
        return
      }
      this.addClassNameToRemoveSymbol();
      

    },
    async innitDataVisualize() {
      await this.getProductsDashboard();
      this.data = this.transferData(this.rawData);
      this.breakDownData = [...this.data]

      this.prepareDataMounted()
     
      this.updateInfoChart();
    },

    statusBtn() {
      if(this.pageCurrent === 0) {
      this.isDisabledNext = this.breakDownData.length === MAX_CHART_ITEMS;
      this.isDisabledPrev = true;

      }else {
        this.isDisabledNext = this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length;
        this.isDisabledPrev = false;
      }
    },
    prepareDataMounted() {
      
      const oldMonthYearSelected = this.dashboard.emissionsByProducts.selectedData;
      let findIndex = this.breakDownData.findIndex(item => item?.month === oldMonthYearSelected?.month);
      if(findIndex < 0) {
        findIndex = this.breakDownData.length - 1;
      }
      this.$emit('selectChart', this.breakDownData[findIndex], window.innerWidth < TABLET_WIDTH);

      // set page current
      this.pageCurrent = findIndex - MAX_CHART_ITEMS < 0 ? 0 : findIndex - MAX_CHART_ITEMS + 1;

       // if not first page
       if(this.pageCurrent !== 0) {

        this.data = [...this.breakDownData];
        const indexToCalc =  findIndex - MAX_CHART_ITEMS;

        const dataKeys = Object.keys(this.data[0]).map(item => {
          return item !== TYPE_CHART ? item : ''
        }).filter(item => item !== '');
        const mediumScore = dataKeys?.reduce((result, key) => {
          result[key.toString()] = this.everyIsNull ? null : (this.data[indexToCalc][key] + this.data[indexToCalc + 1][key]) / 2;
          return result;
        }, {})

        const quantityCut = findIndex - MAX_CHART_ITEMS + 1
        this.data.splice(0, quantityCut);

        // add midpoint into first index arr
        this.data.unshift(mediumScore);

        this.data = [...this.data.slice(0, MAX_CHART_ITEMS + 1)]

        this.chart.axisX.max = MAX_CHART_ITEMS;
        this.chart.axisX.min = 0;

        } else {
          this.data = [...this.breakDownData.slice(0, MAX_CHART_ITEMS)];

          this.chart.axisX.max = MAX_CHART_ITEMS - 1;
          this.chart.axisX.min = undefined;
      }
      this.statusBtn();
      this.addClassNameToRemoveSymbol();
    },
    async getProductsDashboard() {
      const contractor_id = this.$store.state.userData.contractor;

      const listId = this.productMasterDefault.filter(item => item?.id)
      const productDefaultId = listId.length > 0 ? listId.map(item => item?.id) : null
      const productMasterId = this.productsMasterList.filter(item => item?.id).length === 0 ? productDefaultId : this.productsMasterList
      const product_key = getFieldNameDashboardProduct().payload_product_name;
      const payload = {
        contractor_id: contractor_id,
        branch_id: this.branchIds,
        [product_key]: productMasterId,
        type: 1,
        branch_null: this.isHasBranchNull ? 1 : null,
      };
       await getPeriodProductDashboardGeneral(payload,this.$route).then(res => {
        this.rawData = res.data
        this.key += 1
      });
    },
    initializeAnimation(animation){
      this.chartAnimation = animation;
      this.chartAnimation.animationMode = this.animationMode;
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    prepareChartDataValue() {
      let getTotalEmissionsAtIndex0 = [];
      if (this.pageCurrent !== 0) {
        this.data = this.breakDownData.slice()
        let index = this.pageCurrent - 1;

        const dataKeys = Object.keys(this.data[0]).map(item => {
          return item !== TYPE_CHART ? item : ''
        }).filter(item => item !== '');
        const mediumScore = dataKeys?.reduce((result, key) => {
          if(!key.includes('item')) { //  Does not calculate value when key is item name
            result[key.toString()] = this.everyIsNull ? 0 : (this.data[index][key] + this.data[index + 1][key]) / 2;
            if ($_helper_isNumberType(key.toString())) {
              getTotalEmissionsAtIndex0.push((this.data[index][key] + this.data[index + 1][key]) / 2);
            }
          }
          return result;
        }, {})
        this.data.splice(0, this.pageCurrent);
        this.data.unshift(mediumScore);

        this.data = [...this.data.slice(0, MAX_CHART_ITEMS + 1)]

        this.chart.axisX.min = 0;
        this.chart.axisX.max = MAX_CHART_ITEMS;
      }
      else {
        this.chart.axisX.min = undefined;
        this.chart.axisX.max = MAX_CHART_ITEMS - 1;

        this.data = [...this.breakDownData.slice(0, MAX_CHART_ITEMS)]
      }
      this.addClassNameToRemoveSymbol();
      this.addClassNameToRemoveSymbol();
      let totalsEmission = this.data
      .flatMap(obj => obj.product?.product_master ? obj.product.product_master : obj.product) // flatMap the item array into a single array
      .map(item => this.formatNumber(item?.total_emission)); // Get the total_emission attribute value
      const dataCalc = (totalsEmission.concat(getTotalEmissionsAtIndex0));
      this.updateAxisYChart(dataCalc);
    },
    addClassNameToRemoveSymbol() {
      if(this.chart) {
        const el = this.$refs.chart.$el;
        if (el.classList.contains('remove-symbol-size')) {
          el.classList.remove('remove-symbol-size');
  
        }
        if (this.pageCurrent !== 0) {
          if (!el.classList.contains('remove-symbol-size')) {
            el.classList.add('remove-symbol-size');
          }
        }
      }
    },
    next() {
      if (this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length) {
        this.isDisabledNext = true;
        this.isDisabledPrev = false;
        return;
      }

      this.isDisabledNext = false;
      this.pageCurrent = this.pageCurrent + 1;
      if (this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length) {
        this.isDisabledNext = true;
      }
      this.prepareChartDataValue();
      if (this.pageCurrent > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    prev() {
      if (this.pageCurrent <= 0) {
        this.isDisabledPrev = true;
        return;
      }
      this.isDisabledPrev = false;
      this.pageCurrent = this.pageCurrent - 1;
    
      this.prepareChartDataValue();
      if (this.pageCurrent <= 0) {
        this.isDisabledPrev = true;
      }

      if (this.pageCurrent + MAX_CHART_ITEMS < this.breakDownData.length) {
        this.isDisabledNext = false;
      }
    },
    transferData(data) {
      const everyIsNull = data.every(product => product.total_emission === null || product.total_emission === undefined);
      this.everyIsNull = everyIsNull;
      const dataChartPrepare = data?.map(product => {
          const durationTime = moment(product.year + '/' + product.month, 'YYYY/MM').format('YYYY/M');
          let dataItem = {};
          if(!product?.product_master || product?.product_master.length === 0) {
            dataItem.total_emission = everyIsNull ? null : this.formatNumber(product.total_emission);
            dataItem['durationId'] = `${product.month}/${product.year}`;
          }
          else {
              dataItem['durationId'] = `${product.month}/${product.year}`;
              dataItem = product.product_master?.reduce((result, item) => {
                result[item?.id] = this.formatNumber(item?.total_emission);
                dataItem[result.name] = this.formatNumber(item?.total_emission);
                result['item' + item?.id] = item.name;
                  return result;
              }, {});
          }
          dataItem[TYPE_CHART] = durationTime;
          dataItem['duration'] = durationTime;
          dataItem['product'] = product;
          // dataItem['ratio'] = product.ratio;
          // dataItem['total_emission'] = product.total_emission;
          // dataItem['up'] = product.up;
          if(!product?.product_master || product?.product_master.length === 0) {
            dataItem['item' + product?.id] = product.name;
          }
          return dataItem;
      });
      return dataChartPrepare;
    },
    formatNumber(num) {
      return num === 0 ? num : Number(formatValue(num))
    },
    rotateLabelXAxis() {
      if(this.chart) {
        if (window.innerWidth <= 450) {
          this.chart.axisX.labelAngle = -45;
          this.chart.axisX.labelPadding = 12;
        } else {
          this.chart.axisX.labelAngle = 0;
          this.chart.axisX.labelPadding = LABEL_PADDING_Y;
        }
      }
    },
    handleResize() {
      this.rotateLabelXAxis();
    },
    onChartInitialized(flexchart) {
      this.chart = flexchart;
      flexchart.bindingX = TYPE_CHART;
      flexchart.legend.position = 0; // remove chart legend
      flexchart.symbolSize = 8;
      flexchart.axisX.majorTickMarks = false;
      flexchart.axisY.axisLine = true;
      flexchart.axisY.majorGrid = false;
      flexchart.axisX.max = MAX_CHART_ITEMS - 1;
      const chartEl = flexchart.hostElement;
      this.handleEventChart(chartEl);
      flexchart.axisY.labelPadding = LABEL_PADDING_X;
      flexchart.axisX.labelPadding = LABEL_PADDING_Y;
      this.rotateLabelXAxis();
      const el = this.$refs.chart.$el;
      if(this.pageCurrent && this.pageCurrent !== 0) {
        el.classList.add('remove-symbol-size');
      }   
      
    },
    handleEventChart(chartEl) {
      chartEl.addEventListener('mouseleave', this.mouseLeaveEvent);
      chartEl.addEventListener('click', this.clickEvent);
      chartEl.addEventListener('mousemove', this.hoverEvent);
    },
    handleOffEvent() {
      const chartEl = this.chart.hostElement;

      if (chartEl) {
        chartEl.removeEventListener('mouseleave', this.mouseLeaveEvent);
        chartEl.removeEventListener('click', this.clickEvent);
        chartEl.removeEventListener('mousemove', this.hoverEvent);
      }
    },
    updateInfoChart() {
      if (isDashboardProductCFP(this.$route) && this.productMasterDefault.length > 0) {
        this.productMasterDefault.forEach(item => {
          const seriesItem = new Series({ binding: item.id.toString(), name: item.id.toString() });
          this.chart.series.push(seriesItem);
        });
        return;
      }
      const newSeriesConfigurations = Object.keys(this.data[0]).map(item => {
        return item !== TYPE_CHART ? { binding: item, name: item }: {}
      }).filter(item => Object.keys(item).length > 0);
      newSeriesConfigurations.forEach((item) => {
        this.chart.series.push(new Series(item));
      });
      let getTotalEmissionsAtIndex0 = [];
      let index = 0;
      const dataKeys = Object.keys(this.data[0]).map(item => {
          return item !== TYPE_CHART ? item : ''
        }).filter(item => item !== '');
      dataKeys?.forEach((result, key) => {
        if ($_helper_isNumberType(result.toString())) {
          getTotalEmissionsAtIndex0.push(this.data[index][result]);
        }
      });

      let totalsEmission = this.data
      .flatMap(obj => obj.product?.product_master ? obj.product.product_master : obj.product) // flatMap the item array into a single array
      .map(item => this.formatNumber(item?.total_emission)); 

      this.updateAxisYChart(totalsEmission.concat(getTotalEmissionsAtIndex0))
    },
    onChartRendered(flexchart, args) {
      const isExistRects = flexchart.axisX._rects;
      if(isExistRects) {
        this.updateSelected(); 
      }
     
    },
    updateIndexSelectedToSore(item, indexSelected) {
      this.updateEmissionsByProducts({
        ...this.dashboard.emissionsByProducts,
        selectedData: {
          ...this.dashboard.emissionsByProducts.selectedData,
          indexSelected: indexSelected,
          pageCurrent: this.pageCurrent,
          month: item.month,
          year: item.product.year,
          duration_id: item.product.duration_id
        }
      });
    },
    mouseLeaveEvent(e) {
      this.hoverDivStyle = {
        ...this.hoverDivStyle,
        display: 'none'
      }
    },
    clickEvent(e) {
      const chart = this.chart;
      const hitTestInfo = chart.hitTest(e.pageX, e.pageY);
      let index = hitTestInfo.pointIndex;
      if (this.pageCurrent !== 0) {
        if(index === 0) {
          return;
        }
        index = index - 1;
      }
      if(index === -1 || index === null) return;
      this.$emit('selectChart', hitTestInfo.item, window.innerWidth < TABLET_WIDTH, true);
      this.updateIndexSelectedToSore(hitTestInfo.item, index)
      this.addSelectedClass(index);
      if(window.innerWidth < TABLET_WIDTH) {
        this.selectedDivStyle.display = 'none';
        return;
      }
      this.selectedDivStyle = this.updateStylesDiv(index);
      this.selectedDivStyle.backgroundColor = '#e3eded';
    },
    hoverEvent(e) {
      if(window.innerWidth < TABLET_WIDTH) {
        this.hoverDivStyle.display = 'none';
        return;
      }
      const chart = this.chart;
      const hitTestInfo = chart.hitTest(e.pageX, e.pageY);
      if (hitTestInfo.pointIndex === undefined) return;
      let index = hitTestInfo.pointIndex;

      if (this.pageCurrent !== 0) {
        index = index - 1;
      }
      if(index === -1 || index === null) return;
      this.hoverDivStyle = this.updateStylesDiv(index);
    },
    updateStylesDiv(index) {
      const chart = this.chart;
      const chartRect = chart._rectChart;
      const xAxis = chart.axisX;

      return  {
        position: 'absolute',
        bottom:  LABEL_PADDING_X - LABEL_PADDING_Y  + 'px',
        left: xAxis._rects[index]?.left - 20 + 'px',
        width: xAxis._rects[index]?.width + 20 + 'px',
        height: chartRect.height - 4 + 'px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.1s ease-in-out',
        border: '1px dashed #A9A04B',
      };
    },
    formatNumberCustome(num) {
      if(num === null) {
        return '-'
      }
      return formatNumberByConditions(num, { formatUnit: UNIT_SETTING.CO2 });
    },
    customTooltip(ht) {
      const item = ht.item;
      if (!item.product || !ht.name || window.innerWidth < TABLET_WIDTH) return '';
      let name = item['item' + ht.name]
      if(ht?.value === 0 && !name) {
        name = this.productMasterDefault[0]?.name || '' 
      }
      let value = ht.item.total_emission_original;
      let productNameLotNumber;
      if (ht.item.product) {
        const product = ht.item.product.product_master.find(productItem => productItem.id == ht.name);
        productNameLotNumber = `${product.name}/${product.lot_number || ''}`;
        if (product) {
          value = product.total_emission;
        }
      }
      let textName = this.isNewDashboarRoute ? productNameLotNumber : name;
      return `<div style='display: flex;gap: 4px;flex-direction: column;padding: 4px; min-width: 131px; width: 100%;font-family: 'Source Han Sans';'>
                <div style="color: #A9A04B;letter-spacing: 0.03em;line-height: 18px;font-size: 11px;font-weight: 700;">${textName}</div>
                <div style="display: flex;justify-center: space-between; align-items: center; gap: 8px;width: 100%;min-width: 131px;">
                  <div style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;min-width: 45px;'>${this.$t("product_dashboard_main.label_period")}</div>
                  <div style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;'>${(item?.month)}</div>
                </div>
                <div style="display: flex;justify-center: space-between; align-items: center; gap: 8px;min-width: 100%;">
                  <span style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;min-width: 45px;'>${this.$t("product_dashboard_main.tooltip_emission")}</span>
                  <span style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;'>${this.formatNumberCustome(value)} ${this.getUnitChartLineMonth}</span>
                </div>
              </div>`
    },
    addSelectedClass(index) {
      const axisXElement = this.$el.querySelectorAll('.wj-axis-x .wj-label');
      for (let i = 0; i < axisXElement.length; i++) {
        axisXElement[i]?.classList.remove('active-select');
      }
      axisXElement[index]?.classList.add('active-select');
    },
    updateAxisYChart(dataCalc) {
      const calcAxisY = prepareChartAxisYLineChart(dataCalc, 7);
      this.chart.axisY.majorUnit = calcAxisY[2];
      this.chart.axisY.min = calcAxisY[0];
      this.chart.axisY.max = calcAxisY[1];
      this.max = calcAxisY[1];

      this.chart.axisY.itemFormatter = (engine, label) => {
        if (label.val >= (this.max)) {
          label.text = this.getUnitChartLineMonth;
          label.cls += ' unit';
        }
        else {
          label.text = formatYAxisSmallerThan1(numberFormat(label.val.toString()))
        }
        return label;
      };
      this.chart.refresh(true);
    }
  },
  watch: {
    productsMasterList() {
      this.chart.refresh(true);
      this.innitDataVisualize();
    },
    branchIds() {
      this.innitDataVisualize();
    },
    type() {
      if(this.type === 1) {
        this.prepareDataMounted();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/views/dashboard/components/emissions-by-period/yearly/chart-2/index.scss';
</style>
<style lang="scss">
@import './index.scss';
</style>
