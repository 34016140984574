import { isDashboardProductCFP } from '@/api/product/emissionsProduct';
import router from '@/router';
export const getFieldNameDashboardProduct = () => {
  const _route = router.app._route;
  return {
    payload_product_name: isDashboardProductCFP(_route) ? 'cfp_product_ids' : 'product_master_id',
  };
};

export const getUnitNewDashboardCFP = () => {
  const _route = router.app._route;
  return isDashboardProductCFP(_route) ? 'kg-CO2e' : 't-CO2';
};
