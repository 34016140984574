<template>
  <div class="pulldown-wrapper" v-click-outside="handleClickOutSite" @click="handleFocusWiththin" :class="{'disabled' : disabled || itemCount === 0}">
    <div id="dropdownInputText" :class="{'focus-pull-down': focusWithin, 'selection-show': selectionShow, 'has-arrow': arrowDownBtn}" @click="toggleDropdown()">
      <div v-if="!dropdownInputText()[0]" class="placeholder center-text">{{ placeholder }}</div>
      <div v-if="dropdownInputText()[0]" class="input-text">{{ dropdownInputText()[0] }}</div>
      <div v-if="dropdownInputText()[1] && dropdownInputText()[0]" class="count-hide-text">{{ dropdownInputText()[1] }}</div>
    </div>
    <v-dialog
      v-if="selectionShow"
      v-model="selectionShow"
      ref="dialog"
      :width="boxWidth"
      content-class="dialog-popup radio-dialog checkbox-popup"
      @click:outside="handleClickOutSite"
    >
      <div class="head-dialog-popup">
        <span class="head-image-dialog-popup__text">{{placeholder}}</span>
        <div class="selected-count">{{ getSelectedData.length }} {{ $t('product_dashboard_main.label_item_selected') }}</div>
      </div>
      <hr class="divider" />
      <div class="content-dialog-popup">
        <div
          class="selection-pulldown"
          id="style-scroll"
          :class="[methodsList.length > 8 ? '' : 'item-scroll-none', width === '50%' ? '' : 'method-list', isCollapsedProp ? '' : 'dont-collapse']"
        > 
          <div v-if="hasSearch" class="search-text">
            <input v-model="searchText" type="text" class="input-elic" :placeholder="placeHolderInput" style="width: 100%;">
            <img :src="require('@/assets/icons/pulldown/search-icon.svg')" alt="search icon" class="search-icon">
          </div>
          <div
            v-for="(item, index) in methodsList"
            :key="index"
            class="item pull-down-text-color"
          >
            <div v-if="item.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.id), 'is-checked-getall' : (isGetAll(item.text) && itemCount === getSelectedData.length)}">
              <input v-if="isGetAll(item.text)" v-model="getAllData" type="checkbox" :label="item.row_num_pattern" :value="item.id" :id="`${item.id}_${hasSearch}`" />
              <input v-else v-model="getSelectedData" type="checkbox" :label="item.row_num_pattern" :value="item.id" :id="`${item.id}_${hasSearch}`" />
              <label :for="`${item.id}_${hasSearch}`">{{ item.text }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="action-dialog-popup button-bottom-dialog-popup">
        <common-button class="cancle-btn" @action="onCancelSelectData" :label="$t('user_management.button_cancel')"></common-button>
        <common-button class="confirm" @action="onHandleSelectData" type="colored" :label="$t('product_dashboard_main.button_decision')"></common-button>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { calcText } from '@/utils/calcText';
import CheckboxPopup from '@/components/dialogs/checkbox-popup';
import {GET_ALL} from '@/constants/export-report.js';
import { mapState } from "vuex";
import CommonButton from '@/components/utils/button.vue';
import { isDashboardProductCFP } from '@/api/product/emissionsProduct'
export default {
  components: { CheckboxPopup, CommonButton },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'スコープ/カテゴリ/算定方法を選択',
    },
    width: {
      type: String,
      default: '100%'
    },
    arrowDownBtn: {
      type: Boolean,
      default: false,
    },
    selectedData: {
      type: Array,
      default: () => [],
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    isCollapsedProp: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemCount: {
      type: Number,
      default: 0
    },
    showFullText: {
      type: Boolean,
      default: false,
    },
    placeHolderInput: {
      type: String,
      default: '法人を検索',
    }
  },
  data() {
    return {
      selectionShow: false,
      boxWidth     : 0,
      resizeTimer  : null,
      leftPosition : 0,
      isCollapsed: [],
      isCollapsedLayer1: [],
      maxInputLength: 435,
      methodsList: [],
      checkboxKey: 0,
      isChangeGetAll: false,
      getAllData: false,
      searchText: '',
      selectedDataList: [],
      getSelectedData: [],
      focusWithin: false,
    }
  },
  mounted() {
    this.maxInputLength = document.getElementById('dropdownInputText').clientWidth;
    this.methodsList = this.dataList;
    this.getSelectedData = [...this.selectedData];
    this.selectedDataList = [...this.getSelectedData];
    if(this.getSelectedData.length === this.itemCount) {
      this.getAllData = true
    }
   // TODO[Linh]: update logic is_standard => not effect to product
    const isStandardPlan = this.planType.is_standard;
    const isEmissionsView = this.$route.path.includes('emissions/view');
    const isProductPath = this.$route.path.includes('/products') || this.$route.path.includes('/product-emission');
    if (isStandardPlan && !isEmissionsView && !isProductPath) {
      this.$router.push({ path: "/" });
    }
  },
  computed: {
    ...mapState("userData", ["currentUser","planType"]),
    checkCurrentRoute() {
      return isDashboardProductCFP(this.$route);
    },
  },
  watch: {
    getAllData: {
      handler(newValue) {
        if (newValue) {
          this.getSelectedData = [];
          this.methodsList.forEach(item => {
            if (item.id) {
              this.getSelectedData.push(item.id);
            }
          })
          const existIndex = this.getSelectedData.indexOf(GET_ALL);
          if (existIndex !== -1) {
            this.getSelectedData.splice(existIndex, 1);
          }
          this.isChangeGetAll = false;
        } else {
          if(!this.isChangeGetAll) {
            this.getSelectedData = [];
          }
        }
      }
    },
    searchText: {
      handler(value) {
        this.methodsList = this.dataList.filter(item => item.text.toLowerCase().includes(value.toLowerCase()));
      }
    },
    disabled: {
      handler(value) {
        if(value) {
          this.selectedDataList = []
        }
      }
    },
    getSelectedData: {
      handler(val) {
        if (val.length === this.dataList.length - 1) {
          this.getAllData = true;
          this.isChangeGetAll = false;
        } else {
          this.getAllData = false;
          this.isChangeGetAll = true;
        }
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.maxInputLength = document.getElementById('dropdownInputText').clientWidth
      if (this.disabled || this.itemCount === 0) return;
      // set default selected data
      this.getSelectedData = [...this.selectedDataList];
      this.selectionShow = !this.selectionShow;
    },
    handleClickOutSite() {
      if (this.arrowDownBtn) {
        this.selectionShow = false;
        this.focusWithin = false;
      }
    },
    onWindowResize() {
      window.addEventListener("resize", this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener("resize", this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.$refs.box.clientWidth;
      }, 100);
    },
    getArrowImage() {
      return require('@/assets/icons/pulldown/arrow-down.svg');
    },
    onHandleSelectData() {
      this.selectedDataList = [...this.getSelectedData];
      this.selectionShow = false;
      this.focusWithin = false;
      this.dropdownInputText();
      this.$emit('updateProductSelected', this.selectedDataList);
    },
    onCancelSelectData() {
      this.getSelectedData = [];
      this.selectionShow = false;
      this.focusWithin = false;
    },
    dropdownInputText() {
      const productList = this.selectedDataList
        .map((item) => {
          const product = this.methodsList.find((product) => product.id === item);
          if (!product) return null; // Trả về null nếu không tìm thấy sản phẩm

          return this.checkCurrentRoute && product.lot_number
            ? `${product.name}/${product.lot_number}`
            : product.name;
        })
        .filter(Boolean); // Loại bỏ giá trị null hoặc undefined

      return productList.length > 0 ? [productList.join(', '), productList.length] : ['', null];
    },
    isGetAll(text) {
      return text === GET_ALL;
    },
    isCheckedBox(id) {
      return this.getSelectedData.indexOf(id) !== -1;
    },
    handleFocusWiththin() {
      if (this.disabled) return;
      this.focusWithin = true;
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
@import '@/components/dialogs/DialogPopupStyle/index.scss';
.pulldown-wrapper {
  width: 100%;
  &.box-shadow-pull-down{
    #dropdownInputText {
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    }
  }
  &.disabled {
    #dropdownInputText {
      background: $monoLight;
    }
  }
  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  .item-scroll-none {
    overflow: hidden !important;
  }
  .description-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 32px;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    background: $monoOffWhite;
    position: absolute;
    .selected-count {
      color: $monoMid;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }
  .selection-pulldown {
    position: absolute;
    max-height: 370px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    margin-top: 2px;
    z-index: 99;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 429.5px;
    // font-family: 'Source Han Sans JP';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: $monoBlack;
    &.method-list {
      width: calc((100% - 80px));
    }

    .item {
      min-height: 40px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-bottom: 0;
      border-left: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-style: normal;
      display: flex;
      align-items: center;
      position: relative;

      .export-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 0px 9px 16px;
        cursor: pointer;
        input {
          appearance: none;
          width: 16px !important;
          height: 16px !important;
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.25);
          box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          cursor: pointer;
          margin-right: 8px;
        }
        label {
          width: 100%;
          cursor: pointer;
        }
        
        &:hover {
          background: $bgLight;
        }
        input[type=checkbox]:checked {
          appearance: none;
          box-shadow: none;
          text-align: center;
          width: 16px !important;
          height: 16px !important;
          background: $blueMid;
        }
        input[type=checkbox]:checked:after {
          content: url('../../../assets/icons/checkedbox.svg');
          color: $monoWhite;
          position: absolute;
          top: 6px;
          left: 17px;
        }
        input[type=checkbox]:checked:before {
          content: "";
          height: 41px;
          border: 2px solid $blueMid;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .toggle-wrapper {
        width: 100%;
        
        &.layer1,&.layer2 {
          border-left: 0;
          border-right: 0;
        }
        .toggle-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 14px 9px 40px;
          .toggle-icon {
            width: 10px;
            height: 10px;
          }
        }
        &.layer1 {
          .item {
            border-right: 0;
          }
          .export-checkbox {
            padding: 7px 0px 9px 40px;
            input[type=checkbox]:checked:after {
              content: url('../../../assets/icons/checkedbox.svg');
              color: $monoWhite;
              position: absolute;
              top: 6px;
              left: 41px;
            }
          }
        }
      }
    }
    .search-text {
      position: relative;
      .input-elic {
        width: 100% !important;
        margin: unset;
      }
      .search-icon {
        position: absolute;
        right: 9px;
        top: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
  #dropdownInputText {
    height: 40px;
    background: $monoOffWhite;
    box-sizing: border-box;
    box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
    border-radius: 4px;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .input-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $monoBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
    }
    .count-hide-text {
      color: $monoWhite;
      background: $bgDark;
      border-radius: 10px;
      font-size: 12px;
      line-height: 8px;
      width: 19px;
      height: 19px;
      padding: 5px 6px 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: right;
      letter-spacing: 0.03em;
      font-weight: 500;
    }
    .placeholder {
      color: $monoMid;
      // font-family: 'Source Han Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      letter-spacing: 0.03em;
      &.center-text {
        text-align: center;
      }
    }
    .arrow-down {
      position: absolute;
      right: 14px;
    }
  }
  .arrow-top {
    rotate: (180deg);
  }
  &:not(.disabled) {
    #dropdownInputText {
      &:hover {
        background: $goldLight;
        .input-text {
          color: $monoWhite;
        }
        .placeholder {
          &.center-text {
            color: $monoWhite;
          }
        }
        &.selection-show {
          background-color: $monoOffWhite;
          .input-text {
            color: $monoBlack;
          }
        }
      }
      &.focus-pull-down {
        border: 2px solid $blueMid;
        border-radius: 4px;
      }
    }
  }
}
.is-checked-getall {
  position: relative;
  &::before {
    content: '';
    height: 40px;
    border: 2px solid $blueMid;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.checkbox-popup {
  .head-dialog-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    background: $monoOffWhite;
    .selected-count {
      color: $monoMid;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }
  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  .item-scroll-none {
    overflow: hidden !important;
  }
  .selection-pulldown {
    max-height: calc(90vh - 342px);
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    // font-family: 'Source Han Sans JP';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: $monoBlack;
    z-index: 99;

    .item {
      min-height: 40px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-bottom: 0;
      border-left: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-style: normal;
      display: flex;
      align-items: center;
      position: relative;

      .export-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 0px 9px 16px;
        white-space: normal;
        cursor: pointer;
        position: relative;
        input {
          appearance: none;
          width: 16px !important;
          height: 16px !important;
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.25);
          box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          cursor: pointer;
          margin-right: 8px;
          position: relative;

          &[type=checkbox]:checked {
            appearance: none;
            box-shadow: none;
            text-align: center;
            width: 16px !important;
            height: 16px !important;
            background: $blueMid;
          }
          &[type=checkbox]:checked:before {
            content: url('../../../assets/icons/checkedbox.svg');
            color: $monoWhite;
            position: absolute;
            top: -6px;
            left: 0;
          }
        }
        label {
          width: calc(100% - 40px);
          cursor: pointer;
          word-wrap: break-word;
        }
        
        &:hover {
          background: $bgLight;
        }
        &.is-checked {
          &::before {
            content: '';
            height: 100%;
            border: 2px solid $blueMid;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .toggle-wrapper {
        width: 100%;
        cursor: pointer;
        
        &.layer1,&.layer2 {
          border-left: 0;
          border-right: 0;
        }
        .toggle-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 14px 9px 40px;
          white-space: normal;
          &.is-checked {
            &::before {
              content: "";
              height: 40px;
              border: 2px solid $blueMid;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .toggle-icon {
            width: 10px;
            height: 10px;
            &.arrow-top {
              rotate: (180deg);
            }
          }
        }
        &.layer1 {
          .item {
            border-right: 0;
          }
          .export-checkbox {
            padding: 7px 0px 9px 40px;
          }
        }
        .content-container {
          border: 1px solid rgba(42, 42, 48, 0.1);
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
        }
      }
      &:first-child {
        border-top: 0;
      }
    }
    .search-text {
      position: relative;
      background: $monoWhite;
      .input-elic {
        width: 100% !important;
        margin: unset;
        box-shadow: unset;
        background: $monoWhite;
      }
      .search-icon {
        position: absolute;
        right: 9px;
        top: 10px;
        width: 20px;
        height: 20px;
      }
    }

    &.dont-collapse {
      .item {
        .export-checkbox {
          padding: 7px 0px 9px 16px;
        }
        .layer1 {
          .export-checkbox {
            padding: 7px 0px 9px 16px;
          }
          .layer2 {
            .toggle-label {
              display: block;
              padding: 7px 14px 9px 16px;
              font-size: 13px;
              font-weight: 700;
              letter-spacing: 0.05em;
              color: $monoBlack;
              background: rgba(121, 134, 134, 0.12);
              &.is-checked {
                &::before {
                  content: "";
                  height: 40px;
                  border: 2px solid $blueMid;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
            .export-checkbox {
              padding: 7px 14px 9px 16px;
            }
          }
        }
      }
    }
  }
}
@include desktop {
  .pulldown-wrapper {
    .selection-pulldown {
      width: 238px;

      &.method-list {
        width: 496px;
      }
    }
  }
}
</style>