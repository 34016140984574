<template>
  <div class="product-peried">
    <page-title :label="$t('product_dashboard_main.title_emissions_per_product')" iconPath="page-title-icon.svg" />
    <page-filter
      :isGetAllProducts="isShowColumnChart"
      @update="updateSelectedChart"
      :productList="productList"
      @selectProductsMaster="handleSelectProductsMaster"
      :productsMasterListDefault="productsMasterList"
    />
    <tab-header :tab="type" @update:tab="handleUpdateTab" />
    <div v-if="isShowColumnChart" class="product-peried-chart mt-12">
      <v-tabs-items v-model="type" class="product-peried-tab">
        <v-tab-item class="product-peried-tab-item">
          <chart-year
            v-if="type === 0"
            @selectChart="handleSelectChart"
            :branchIds="branchIds"
            :isHasBranchNull="isHasBranchNull"
            class="month-chart"
          />
          <chart-detail :isChartYear="true" :chartDetail="chartDetail" class="month-chart-detail" />
        </v-tab-item>

        <v-tab-item class="product-peried-tab-item">
          <chart-month
            v-if="type === 1"
            @selectChart="handleSelectChart"
            :branchIds="branchIds"
            :isHasBranchNull="isHasBranchNull"
            class="month-chart"
          />
          <chart-detail :chartDetail="chartDetail" class="month-chart-detail" />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <chart-detail-popup
      v-if="isShowColumnChart"
      :detailDataChart="chartDetail"
      :dialog="dialogChartDetail"
      @close="dialogChartDetail = false"
    />
    <div v-if="!isShowColumnChart" class="product-peried-chart mt-12">
      <v-tabs-items v-model="type" class="product-peried-tab">
        <v-tab-item class="product-peried-tab-item">
          <line-chart-year
            v-if="type === 0"
            @selectChart="handleSelectChart"
            :branchIds="branchIds"
            :productsMasterList="productsMasterList"
            :productMasterDefault="getListProductName"
            :durationList="durationList"
            :isHasBranchNull="isHasBranchNull"
            class="month-chart chart-line-year"
            :type="type"
          />
          <chart-detail
            :chartDetail="chartDetail"
            :productNames="getListProductName"
            :isChartYear="true"
            chartType="line"
            class="month-chart-detail"
          />
        </v-tab-item>

        <v-tab-item class="product-peried-tab-item">
          <line-chart-month
            v-if="type === 1"
            @selectChart="handleSelectChart"
            :branchIds="branchIds"
            :productsMasterList="productsMasterList"
            :productMasterDefault="getListProductName"
            :durationList="durationList"
            :isHasBranchNull="isHasBranchNull"
            class="month-chart"
            :type="type"
          />
          <chart-detail
            :chartDetail="chartDetail"
            :productNames="getListProductName"
            chartType="line"
            class="month-chart-detail"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <chart-detail-popup
      v-if="!isShowColumnChart"
      :detailDataChart="chartDetail"
      :dialog="dialogChartDetail"
      @close="dialogChartDetail = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PageTitle from '@/components/products/common/page-title.vue';
import PageFilter from '@/components/products/common/page-filter.vue';
import TabHeader from '@/components/products/common/tab-header.vue';
import ChartMonth from '@/components/products/charts/period/column-chart/month-chart.vue';
import ChartYear from '@/components/products/charts/period/column-chart/year-chart.vue';
import ChartDetail from '@/components/products/charts/period/chart-detail.vue';
import ChartDetailPopup from '@/components/products/dialogs/DetailChartPopupSP.vue';
import LineChartMonth from '@/components/products/charts/period/line-chart/month-chart.vue';
import LineChartYear from '@/components/products/charts/period/line-chart/year-chart.vue';
import { PRODUCT_DASHBOARD } from '@/constants/products/define-data';
import { chartTitleMonthYear } from '@/utils/getTextWithCondition';
import moment from 'moment';
export default {
  name: 'ProductByPeriod',
  components: {
    PageTitle,
    PageFilter,
    TabHeader,
    ChartMonth,
    ChartDetail,
    ChartYear,
    ChartDetailPopup,
    LineChartMonth,
    LineChartYear,
  },
  props: {
    durationList: {
      type: Array,
      default: () => [],
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    productList: {
      type: Array,
      default: () => [],
    },
    isHasBranchNull: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: 0,
      chartDetail: {},
      dialogChartDetail: false,
      isShowColumnChart: true,
      productsMasterList: [],
    };
  },
  created() {
    // set default data
    this.isShowColumnChart = this.dashboard.emissionsByProducts.isGetAllProduct === false ? false : true;
    this.productsMasterList = this.dashboard.emissionsByProducts.productMasterIds;
    this.type = this.dashboard.emissionsByProducts.selectedType;
  },
  watch: {
    isShowColumnChart: {
      handler() {
        this.updateDataToStore();
      },
    },
  },
  computed: {
    ...mapState('product', ['dashboard', 'colors']),
    getListProductName() {
      const realProductList = this.productList.filter((item) => item.id);
      const mapProductListData = (list) =>
        list.map((item, index) => ({
          id: item.id,
          name: item?.name,
          color: this.colors[index],
          lotNumber: item?.lot_number,
        }));

      if (this.productsMasterList.length > 0) {
        // has selected products

        // filter products selected matching pulldown current
        const realProductListSelected = realProductList.filter((item) => this.productsMasterList.includes(item.id));

        if (realProductListSelected.length > 0) {
          return mapProductListData(realProductListSelected);
        }
      }
      return mapProductListData(realProductList); // don't selected products
    },
  },
  methods: {
    ...mapActions('product', ['updateEmissionsByProducts']),
    handleUpdateTab(newTab) {
      this.type = newTab;
      this.updateDataToStore();
    },
    handleSelectChart(chartItem, isMobile, isClicked = false) {
      const product = chartItem?.product;
      if (isMobile && isClicked) {
        this.dialogChartDetail = true;
      }
      // update duration text
      let duration = '';
      let chartItems = {};
      if (this.isShowColumnChart) {
        // column chart
        if (this.type === 1) {
          const month = moment(chartItem.month, 'M').format('M');
          const year = moment(chartItem.year, 'YYYY').format('YYYY');
          duration = chartTitleMonthYear(3, year, 0, month); // tab = 0 | 1 | 2 | 3,  year, order = 0, month, type = ''
        } else {
          const year = chartItem?.year;
          duration = chartTitleMonthYear(0, year); // tab = 0 | 1 | 2 | 3, year, order = 0, month, type = ''
        }

        chartItems = {
          ...chartItem,
          type: this.type,
          time_range: duration,
          month: chartItem?.month,
          total_emission: chartItem?.total_emission,
          up: chartItem?.up,
          ratio: chartItem?.ratio,
        };

        this.chartDetail = chartItems;
      } else {
        // line chart
        if (this.type === 1) {
          const month = moment(product.month, 'M').format('M');
          const year = moment(product.year, 'YYYY').format('YYYY');
          duration = chartTitleMonthYear(3, year, 0, month); // tab = 0 | 1 | 2 | 3, year, order = 0, month, type = ''
        } else {
          const year = chartItem?.year;
          duration = chartTitleMonthYear(0, year); // tab = 0 | 1 | 2 | 3, year, order = 0, month, type = ''
        }

        chartItems = {
          ...chartItem,
          type: this.type,
          time_range: duration,
          month: chartItem?.month,
          total_emission: chartItem?.product?.total_emission,
          up: chartItem?.product?.up,
          ratio: chartItem?.product?.ratio,
          start_at: chartItem?.product?.start_at,
          end_at: chartItem?.product?.end_at,
        };

        this.chartDetail = chartItems;
      }
      this.updateDataToStore();
    },
    updateSelectedChart(value) {
      this.isShowColumnChart = PRODUCT_DASHBOARD.GET_ALL_PRODUCT === value;
    },
    handleSelectProductsMaster(value) {
      this.productsMasterList = value;
      this.updateDataToStore();
    },
    updateDataToStore() {
      if (this.isShowColumnChart) {
        this.productsMasterList = [];
      }
      this.updateEmissionsByProducts({
        isGetAllProduct: this.isShowColumnChart,
        productMasterIds: this.productsMasterList,
        selectedType: this.type,
        selectedData: {
          selectedYear: this.chartDetail.year || this.dashboard.emissionsByProducts.selectedData.selectedYear,
          month: this.chartDetail.month || this.dashboard.emissionsByProducts.selectedData.month,
          year: this.chartDetail.year || this.dashboard.emissionsByProducts.selectedData.year,
          indexSelected: this.dashboard.emissionsByProducts.selectedData.indexSelected,
          pageCurrent: this.dashboard.emissionsByProducts.selectedData.pageCurrent,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-peried {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // gap: 16px;
  width: 100%;
  &-chart {
    width: 100%;
    .product-peried-tab {
      background-color: transparent;
      width: 100%;
    }
    .month-chart-detail {
      display: none;
    }
  }
}

@include desktop {
  .product-peried {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 16px;
    width: 100%;
    &-chart {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      .month-chart {
        width: 65%;
      }
      .month-chart-detail {
        display: flex;
        width: calc(100% - 16px - 54px - 65%);
      }
    }
  }
}
@media (max-width: 450px) {
  .product-peried-chart {
    margin-bottom: 0 !important;
  }
  .product-peried {
    margin-bottom: 16px !important;
  }
}
</style>
